import TitleList from 'components/TitleList'

import styled from 'styled-components'
import { useLaunchpads } from 'state/launchpad/hooks'
import { getJoiNFTAddress } from 'utils/addressHelpers'
import NFTItem from '../NFTItem'

const BlockListNFT = styled.div`
  margin: 50px 0px;
  @media screen and (max-width: 1199px) {
    margin: 25px 0px;
  }
`
const BlockListNFTWrap = styled.div``
const BlockListNFTItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 1199px) {
    justify-content: center;
  }
`
  
const ListNFT = () => {
  const { launchpads } = useLaunchpads(getJoiNFTAddress())

  return (
    <div className="blockContainer">
      <TitleList nameList="NFT COSMOS META" />
      {/* <CountNFT launchpads={launchpads} /> */}
      <BlockListNFT>
        <BlockListNFTWrap>
          <BlockListNFTItem>
            {launchpads.map((item, key) => (
              <NFTItem
                key={item._id}
                nft={item}
                isLast={key === launchpads.length - 1}
                isMint={false}
                qty={item.quantity}
              />
            ))}
          </BlockListNFTItem>
        </BlockListNFTWrap>
      </BlockListNFT>
    </div>
  )
}

export default ListNFT
